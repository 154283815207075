import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Scanner from '../scanner/Scanner';
import { AppContext } from '../../context/appContext';
import CameraHandler from '../../controller/cameraHandler/CameraHandler';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import '../../styles/main.css';
import Configs from '../../Configs';
import { useHistory } from 'react-router-dom';
import Popup from '../popup/Popup';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import FetchLatestConfigHandler from '../../controller/eventHandler/FetchLatestConfigHandler';
import { isLatestConfigAvailable } from '../../services/fetchLatestConfig';
import { useErrorHanlder } from '../../context/errorContext';

const Main = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const [, setIsResult] = useState(false);
  const [showLatestConfigModal, setShowLatestConfigModal] = useState(false);
  const { onLoadSync, reason, isScanIn, setIsScanIn, showFeedbackModalPopup,scanCount , setScanCount } = useContext(AppContext);
  const [cameraPermission, setCameraPermission] = useState(
    CameraHandler.isCameraPermissionGranted,
  );
  const [showScanCountPopup, setShowScanCountPopup] = useState(false);

  const { showErrorNotification } = useErrorHanlder();

  useEffect(() => {
    if (ScanInHandler.getIsScanInInfo() === 'false') {
      setIsScanIn(false);
    } else {
      setIsScanIn(true);
    }
    if (!onLoadSync) {
      history.push(Configs.SYNC.PATH);
    }
  }, []);

  const scanOutHandler = () => {
    setIsScanIn(false);
    ScanInHandler.setIsScanInInfo(false);
  };

  const scanInHandler = () => {
    setIsScanIn(true);
    ScanInHandler.setIsScanInInfo(true);
  };

  const showResult = () => {
    setIsResult(true);
    setTimeout(() => {
      setIsResult(false);
    }, 1000);
  };

  useEffect(() => {
    CameraHandler.cameraPermissionGranted(true);
    setCameraPermission(true);
    return () => {
      clearTimeout(showResult);
    };
  }, [cameraPermission]);

  useEffect(() => {
    if (scanCount > 1 && showFeedbackModalPopup) {
      setShowScanCountPopup(true);
      const timer = setTimeout(() => {
        setShowScanCountPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [scanCount, showFeedbackModalPopup]);

  const onSuccessCallback = (response) => {
    setShowLatestConfigModal(response?.isLatestConfigAvailable)
  };

  const onErrorCallback = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  }

  useEffect(() => {
    const checkAndFetchConfig = () => {
      const lastModalDate = FetchLatestConfigHandler.getModalLastDisplayDate();
      const currentDate = new Date().toISOString().slice(0, 10);
  
      if (lastModalDate !== currentDate) {
        isLatestConfigAvailable(onSuccessCallback, onErrorCallback);
      }
    };
  
    // Initial check
    checkAndFetchConfig();
  
    // Set up polling interval
    const pollingInterval = setInterval(checkAndFetchConfig, 60000);
  
    // Clean up interval on component unmount
    return () => clearInterval(pollingInterval);
  }, []);
  
  const closeModal = () => {
    setShowLatestConfigModal(false);
    FetchLatestConfigHandler.setModalLastDisplayDate(new Date().toISOString().slice(0, 10));
  };

  const handlePopupSubmit = () => {
    history.push(Configs.SYNC.PATH, { from: location.pathname });
    closeModal();
  }

  return (
    <div className='main-container'>
      {showLatestConfigModal
       ? <Popup
          icon={faExclamationCircle}
          title='New configuration available. Scanning will resume once the update is complete'
          onClose={closeModal}
          submitBtnLabel='Fetch latest configuration'
          onSubmit={handlePopupSubmit}>
        </Popup>
      : null}

      {showScanCountPopup ? 
        <Popup
          icon={faExclamationCircle}
          showCloseBtn = {false}
          title='Please close the feedback response before continuing to scan'
        />
        : null }
      <div className='scan-btn-group'>
        <button
          type='button'
          className={`scan-in-btn ${isScanIn ? 'active' : ''}`}
          onClick={scanInHandler}
        >
          {t('scan_scanInText')}
        </button>
        <button
          type='button'
          className={`scan-out-btn ${isScanIn ? '' : 'active'}`}
          onClick={scanOutHandler}
        >
          {t('scan_scanOutText')}
        </button>
      </div>
      {reason && (
        <div>
          <div className='scan-override-text' id='scan-override-text'>
            {t('scan_overrideTitle')} {reason}
          </div>
        </div>
      )}
      {/* toggle camera and loading */}
      {cameraPermission && onLoadSync ? <Scanner showLatestConfigModal={showLatestConfigModal} setScanCount= {setScanCount} scanCount = {scanCount} />
       : <div className='scanner-off' />}
    </div>
  );
};

export default Main;
