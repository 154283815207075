import Cookies from 'universal-cookie';

const cookies = new Cookies();

class SyncDataHandler {
  static setSyncData(data) {
    localStorage.setItem('SYNC_DATA', JSON.stringify(data));
  }

  static getSyncData() {
    const syncData = localStorage.getItem('SYNC_DATA');
    return syncData;
  }

  static removeSyncData() {
    localStorage.removeItem('SYNC_DATA');
  }

  static setLastConfigFetchDate(info) {
    cookies.set('LAST_CONFIG_FETCH_DATE', info, { maxAge: 2147483647 });
  }

  static getLastConfigFetchDate() {
    return cookies.get('LAST_CONFIG_FETCH_DATE');
  }

  static removeLastConfigFetchDate() {
    return cookies.remove('LAST_CONFIG_FETCH_DATE');
  }
}

export default SyncDataHandler;
