import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Popup = (props) => {
  const { onClose, icon, title, showSubmitBtn = true, onSubmit, submitBtnLabel, children, showCloseBtn = true } = props;

  const handleClose = () => {
    onClose?.();
  };

  return (
    <>
        <div className='popup-overlay'>
          <div className='popup'>
            <div className='popup-header'>
                <FontAwesomeIcon icon={icon} style={{color: "#FFD43B"}} />
                <span className='popup-title'>{title}</span>
                {showCloseBtn ? <FontAwesomeIcon icon={faTimes} onClick={handleClose}/>: null}
            </div>
            <div className='popup-body'>{children}</div>
            <>
            { showSubmitBtn
              ? <div className='popup-footer'>
                <Button
                  cName='popup-submit-btn'
                  funct={onSubmit}
                  text={submitBtnLabel || ''}
                />
              </div> 
              : null
            }
            </>
          </div>
        </div>
    </>
  );
};

Popup.propTypes = {
  icon: PropTypes.IconDefinition,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  showSubmitBtn: PropTypes.bool,
  submitBtnLabel: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Popup;