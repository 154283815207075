import AlertSound from './assets/sounds/light-button.ogg';
import SuccessSound from './assets/sounds/success-chime.ogg';
import FailSound from './assets/sounds/fail-buzz.ogg';
import { BarcodeFormat } from '@zxing/library';


const Configs = {
  HttpMethodConstants: {
    POST: 'POST',
    GET: 'GET',
  },
  PAIRSCANNER: {
    PATH: '/pairScanner',
    API_PATH: 'scannerProfile/pair/',
    API_METHOD: 'POST',
    SUCCESS_MSG: 'pairScanner_successText', // 'Pairing Success!',
    SUCCESS_DISPLAY_DURATION: 1000,
    FAIL_MSG: 'pairScanner_failText', // 'Pairing Failed!',
    FAIL_DISPLAY_DURATION: 3000,
    UNPAIR_API_PATH: 'scannerProfile/unpair/',
    UNPAIR_API_METHOD: 'POST',
  },
  LOGIN: {
    PATH: '/login',
    API_PATH: 'scannerUser/login/',
    API_METHOD: 'POST',
    SUCCESS_MSG: '', // 'Login Success!',
    SUCCESS_DISPLAY_DURATION: 1000,
    FAIL_MSG: 'login_failText', // 'Incorrect username or password',
    INACTIVE_SCANNER: 'login_inactive_scanner',
    FAIL_DISPLAY_DURATION: 3000,
  },
  SYNC: {
    PATH: '/sync',
    API_PATH: 'scannerProfile/sync/',
    API_METHOD: 'GET',
    SUCCESS_MSG: 'sync_successText', // 'Sync Success!',
    SUCCESS_DISPLAY_DURATION: 3000,
    FAIL_DISPLAY_DURATION: 3000,
    TOTAL_SYNC_DURATION: 5000,
    FAIL_MSG: 'sync_failText', // 'Sync Failed! \nProceed in Offline Mode',
  },
  EVENT: {
    PATH: '/event',
    TIMEOUT_DURATION: 1000,
  },
  SCAN: {
    PATH: '/',
    API_PATH: 'validate/',
    API_METHOD: 'POST',
    SOUND: AlertSound,
    SUCCESS_SOUND: SuccessSound,
    SUCCESS_VIBRATE_DURATION: 200,
    FAIL_SOUND: FailSound,
    RESULT_DISPLAY_DURATION: 1000,
    FAIL_VIBRATE_DURATION: 1000,
    SCANNER_WAIT_TIME: 1500
  },
  OVERRIDE: {
    PATH: '/override',
    REASON: [
      'override_reason_1',
      'override_reason_2',
      'override_reason_3',
      'override_reason_4',
    ],
    TIMEOUT_DURATION: 1000,
    SUCCESS_DISPLAY_DURATION: 1000,
  },
  MANUALENTRY: {
    PATH: '/manualEntry',
    RESULT_DISPLAY_DURATION: 1000,
  },
  VALIDATORENTRY: {
    PATH: '/validatorEntry',
    RESULT_DISPLAY_DURATION: 1000,
  },
  MANUALENTRYTRANSACTION: {
    API_PATH: 'fetchTickets/',
    API_METHOD: 'GET',
    ALERT_MSG_SHOW_AT: 100,
  },
  SETTING: {
    PATH: '/setting',
  },
  HEALTHCHECK: {
    API_PATH: 'healthCheck/',
    API_METHOD: 'POST',
    TRIGGER_DURATION: process.env.HEALTHCHECK_TIMER || 30000, // reduced to 30 sec for this jira: https://bmssea.atlassian.net/browse/BIG-30298
    OFFLINE_SYNC_API_PATH: 'offlineBatchSync',
    OFFLINE_SYNC_API_METHOD: 'POST',
  },
  OFFLINE_BATCH_SYNC: {
    PATH: '/offlineBatchSync',
    API_PATH: 'healthCheck/data.php',
    API_METHOD: 'POST',
    ARCHIVAL_DURATION_BY_DAYS: 30,
  },
  OFFLINE_BATCH_SYNC_DETAILS: {
    PATH: '/offlineBatchSyncDetails',
    API_PATH: 'healthCheck/data.php',
    API_METHOD: 'POST',
  },
  REPORT: {
    PATH: '/report',
  },
  REPORT_DETAILS: {
    PATH: '/reportDetails',
    API_PATH: 'statistic',
    API_METHOD: 'GET',
  },
  GATE_REPORT: {
    PATH: '/gateReport',
    STAFF_DENY_ENTRY_DURATION : 2000
  },
  GATE_REPORT_DETAILS: {
    PATH: '/gateReportDetails',
    API_PATH: 'statistic/gate',
    API_METHOD: 'GET',
  },
  SCAN_COUNT_REPORT: {
    PATH: '/scanCountReport',
    DURATION: 7,
    API_PATH: 'statistic/scanCount',
    API_METHOD: 'GET',
  },
  SCAN_COUNT_REPORT_DETAILS: {
    PATH: '/scanCountReportDetails'
  },
  RESET_DEVICE: {
    PATH: '/reset',
    SUCCESS_MSG: 'reset_successText',
    FAIL_MSG: 'reset_failText',
  },
  ERROR: {
    MSG_401: 'invalid',
    CODE_401: '401',
  },
  SPLASH_SCREEN: {
    DISPLAY_DURATION: 2000,
  },
  VIEW_AUDIT: {
    PATH: '/viewAudit',
    API_PATH_BY_BARCODE: 'barcodeAdmissionAudit?barcode=',
    API_PATH_BY_TICKETID: 'barcodeAdmissionAudit?ticketId=',
    API_METHOD: 'GET',
  },
  BARCODE_AUDIT: {
    PATH: '/barcodeAudit',
  },
  BARCODE_AUDIT_MANUALENTRY: {
    PATH: '/barcodeAuditManualEntry',
  },
  FETCH_LATEST_CONFIG: {
    API_PATH: 'event/checkLatestConfigAvailable',
    API_METHOD: 'POST',
  },
  BARCODE_FORMAT_SUPPORTS:[BarcodeFormat.QR_CODE,BarcodeFormat.CODE_128,BarcodeFormat.ITF,BarcodeFormat.PDF_417]
};

export default Configs;
