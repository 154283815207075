import React, { useEffect, useMemo, useState } from 'react';
// import Cookies from 'universal-cookie';
import { Switch, Route } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core';
import AppBarMenu from './components/appbarMenu/AppBarMenu';
import { AppContext } from './context/appContext';
import {
  LoginProtectedRoute,
  ProtectedRoute,
} from './components/protectedRoute/ProtectedRoute';
// import Queries from './database/dbQuery';
import Login from './components/login/Login';
import PairScanner from './components/pairScanner/PairScanner';
import SyncBarcode from './components/offlineBatch/SyncBarcode';
import SelectEvent from './components/offlineBatch/SelectEvent';
import Main from './components/main/Main';
import Setting from './components/setting/Setting';
import OverrideResult from './components/resultDisplay/OverrideResult';
import ManualEntry from './components/scanner/ManualEntry';
import ValidatorEntry from './components/scanner/ValidatorEntry';
import offlineBatchSync from './components/offlineBatch/OfflineBatchSync';
import offlineBatchSyncDetails from './components/offlineBatch/OfflineBatchSyncDetails';
import Report from './components/report/Report';
import ReportDetails from './components/report/ReportDetails';
import PairScannerAuth from './controller/authHandler/PairScannerAuthHandler';
import Auth from './controller/authHandler/AuthHandler';
import AppModeHandler from './controller/appModeHandler/AppModeHandler';
import './styles/App.css';
import * as constants from './constants/constants';
import ScannerUserHandler from './controller/authHandler/ScannerUserHandler';
import Reset from './components/reset/Reset';
import SplashScreen from './components/splashScreen/SplashScreen';
import Configs from './Configs';
import GateReport from './components/report/GateReport';
import GateReportDetail from './components/report/GateReportDetail';
import ViewAudit from './components/audit/ViewAudit';
import BarcodeAudit from './components/audit/BarcodeAudit';
import BarcodeAuditManualEntry from './components/audit/BarcodeAuditManualEntry';
import ScanCountReport from './components/report/ScanCountReport';
import ScanCountReportDetail from './components/report/ScanCountReportDetail';
import ScannerUserRoleHandler from './controller/authHandler/ScannerUseRoleHandler';
import { ErrorProvider } from './context/errorContext';
import ErrorBoundaryWithContext from './components/errorBoundary/errorBoundary';

const App = function () {
  const [isSplashScreen, setSplashScreen] = useState(true);
  // const cookies = new Cookies();
  // connection check - Wifi or network is connected or not
  const [connection, setConnection] = useState(navigator.onLine);
  // change app state to emergency mode
  const [appMode, setAppMode] = useState(constants.AppModeOnline);
  // set validator button
  const [validatorMode, setValidatorMode] = useState(constants.ValidatorModeOff);

  const [voucherMode, setVoucherMode] = useState(false);
  // authentication token - user is logged or not
  const [token, setToken] = useState(Auth.getToken);
  // pair scanner info
  const [scannerInfo, setScannerInfo] = useState(
    PairScannerAuth.getScannerInfo,
  );
  // scanner user
  const [scannerUser, setScannerUser] = useState(
    ScannerUserHandler.getScannerUser,
  );
  // scanner user role
   const [scannerUserRole, setScannerUserRole] = useState(ScannerUserRoleHandler.getScannerUserRole);
  // reopen load app sync data
  const [onLoadSync, setOnLoadSync] = useState(false);
  // scan in / out
  const [isScanIn, setIsScanIn] = useState(true);
    // barcode scanned or not
  const [barcodeScan, setBarcodeScan] = useState(false);
  // barcode info
  const [barcode, setBarcode] = useState({});
  // override reason
  const [reason, setReason] = useState();
  // audit barcode info
  const [auditBarcode, setAuditBarcode] = useState({});
  // audit ticket ID info
  const [auditTicketID, setAuditTicketID] = useState({});
  // from path
  const [fromPath, setFromPath] = useState();
  // only 1 event & gate
  const [isSingleEventGate, setIsSingleEventGate] = useState(false);
  // is result feedback open or not
  const [showFeedbackModalPopup, setShowFeedbackModalPopup] = useState(false)
  // count the scan
  const [scanCount , setScanCount] = useState(0)
  // use meno to change state
  const providerValue = useMemo(
    () => ({
      appMode,
      setAppMode,
      validatorMode,
      setValidatorMode,
      voucherMode,
      setVoucherMode,
      token,
      setToken,
      connection,
      setConnection,
      scannerInfo,
      setScannerInfo,
      scannerUser,
      setScannerUser,
      scannerUserRole,
      setScannerUserRole,
      onLoadSync,
      setOnLoadSync,
      barcode,
      setBarcode,
      barcodeScan,
      setBarcodeScan,
      reason,
      setReason,
      auditBarcode,
      setAuditBarcode,
      auditTicketID,
      setAuditTicketID,
      fromPath,
      setFromPath,
      isScanIn,
      setIsScanIn,
      isSingleEventGate,
      setIsSingleEventGate,
      showFeedbackModalPopup,
      setShowFeedbackModalPopup,
      scanCount , 
      setScanCount
    }),
    [
      appMode,
      setAppMode,
      validatorMode,
      setValidatorMode,
      voucherMode,
      setVoucherMode,
      token,
      setToken,
      connection,
      setConnection,
      scannerInfo,
      setScannerInfo,
      scannerUser,
      setScannerUser,
      scannerUserRole,
      setScannerUserRole,
      onLoadSync,
      setOnLoadSync,
      barcode,
      setBarcode,
      barcodeScan,
      setBarcodeScan,
      auditBarcode,
      setAuditBarcode,
      auditTicketID,
      setAuditTicketID,
      reason,
      setReason,
      fromPath,
      setFromPath,
      isScanIn,
      setIsScanIn,
      isSingleEventGate,
      setIsSingleEventGate,
      showFeedbackModalPopup,
      setShowFeedbackModalPopup,
      scanCount , 
      setScanCount
    ]
  );

  useEffect(() => {
    setTimeout(() => {
      setSplashScreen(false);
    }, Configs.SPLASH_SCREEN.DISPLAY_DURATION);
  }, [isSplashScreen]);

  useEffect(() => {
    // saveBarcodeToDB();
    // listen to network status wheather online or offline
    window.addEventListener('online', () => {
      setConnection(navigator.onLine);
    });
    window.addEventListener('offline', () => {
      setConnection(navigator.onLine);
    });
  }, [AppModeHandler.appMode()]);

  return (
    <>
     <ErrorProvider>
      <ErrorBoundaryWithContext>
          {isSplashScreen ? (
            <SplashScreen />
          ) : (
        <StylesProvider injectFirst>
          <AppContext.Provider value={providerValue}>
            <AppBarMenu />
            <Switch>
              <Route exact path='/pairScanner' component={PairScanner} />
              <LoginProtectedRoute exact path='/login' component={Login} />
              <ProtectedRoute exact path='/' component={Main} />
              <ProtectedRoute exact path='/sync' component={SyncBarcode} />
              <ProtectedRoute exact path='/event' component={SelectEvent} />
              <ProtectedRoute exact path='/setting' component={Setting} />
                  <ProtectedRoute
                    exact
                    path='/override'
                    component={OverrideResult}
                  />
                  <ProtectedRoute
                    exact
                    path='/viewAudit'
                    component={ViewAudit}
                  />
                    <ProtectedRoute
                        exact
                        path='/barcodeAudit'
                        component={BarcodeAudit}
                    />
                    <ProtectedRoute
                        exact
                        path='/barcodeAuditManualEntry'
                        component={BarcodeAuditManualEntry}
                    />
                  <ProtectedRoute
                    exact
                    path='/manualEntry'
                    component={ManualEntry}
                  />
              <ProtectedRoute
                exact
                        path='/validatorEntry'
                        component={ValidatorEntry}
                    />
                  <ProtectedRoute
                    exact
                path='/offlineBatchSync'
                component={offlineBatchSync}
              />
              <ProtectedRoute
                exact
                path='/offlineBatchSyncDetails'
                component={offlineBatchSyncDetails}
              />
              <ProtectedRoute exact path='/report' component={Report} />
              <ProtectedRoute
                exact
                path='/reportDetails'
                component={ReportDetails}
              />
                  <ProtectedRoute exact path='/gateReport' component={GateReport} />
                  <ProtectedRoute
                    exact
                    path='/gateReportDetails'
                    component={GateReportDetail}
                  />
                  <ProtectedRoute exact path='/scanCountReport' component={ScanCountReport} />
                  <ProtectedRoute
                    exact
                    path='/scanCountReportDetails'
                    component={ScanCountReportDetail}
                  />
              <ProtectedRoute exact path='/reset' component={Reset} />
            </Switch>
          </AppContext.Provider>
        </StylesProvider>
          )}
        </ErrorBoundaryWithContext>
      </ErrorProvider>
    </>
  );
};

export default App;
