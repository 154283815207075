class ScannerDataHandler {
  static setScannerData(data) {
    localStorage.setItem('SCANNER_DATA', JSON.stringify(data));
  }

  static getScannerData() {
    const scannerData = localStorage.getItem('SCANNER_DATA');
    return scannerData;
  }

  static removeScannerData() {
    localStorage.removeItem('SCANNER_DATA');
  }
}

export default ScannerDataHandler;
